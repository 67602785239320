import { Fade, Tooltip, type SxProps, type TooltipProps } from '@mui/material';
import type { JSX } from 'react';
import { rem } from '~/utils/utils';

type Props = {
  enterDelay?: number;
  enterNextDelay?: number;
  title: string | JSX.Element;
  children: TooltipProps['children'];
  theme?: 'light' | 'dark';
  offset?: [number, number];
  style?: SxProps<{ padding: string }>;
  transitionProps?: TooltipProps['TransitionProps'];
  enabled?: boolean;
};

const Component = ({
  enterDelay,
  enterNextDelay,
  title,
  style,
  children,
  theme,
  offset = [0, -6],
  transitionProps,
  enabled = true,
}: Props) =>
  enabled ? (
    <Tooltip
      title={title}
      // @ts-expect-error - Fade is not typed
      TransitionComponent={Fade}
      TransitionProps={
        transitionProps ?? {
          timeout: {
            enter: 200,
            exit: 100,
          },
        }
      }
      enterDelay={enterDelay ?? 200}
      enterNextDelay={enterNextDelay ?? 0}
      componentsProps={{
        popper: {
          sx: {
            // zIndex: 0,
            '& .MuiTooltip-tooltip': {
              padding: `${rem(4)} ${rem(9)} ${rem(5)}`,
              ...style,
            },
          } as SxProps<object>,
          modifiers: [
            {
              name: 'offset',
              options: { offset },
            },
          ],
        },
        tooltip: {
          sx: {
            fontFamily: 'var(--font)',
            fontSize: 12.5,
            backgroundColor: theme === 'dark' ? '' : '#000',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );

export default Component;
