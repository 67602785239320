import { ErrorOutline, OpenInNew } from '@mui/icons-material';
import { CircularProgress, Paper, Skeleton } from '@mui/material';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'wouter';
import { useHashLocation } from 'wouter/use-hash-location';
import * as appStyles from '~/app.css';
import * as pageStyles from '~/components/Page/Page.css';
import { Edit } from '~/elements/Buttons/Edit';
import Tooltip from '~/elements/Tooltip';
import { colors } from '~/theme.css';
import { DEV, isBun } from '~/utils/constants';
import { type DriveFile } from '~/utils/DriveAPI';
import { useLast } from '~/utils/hooks';
import { parseExport } from '~/utils/parseExport';
import { type PropsOf } from '~/utils/types';
import {
  getMimeTypeNameFromFile,
  isADrawing,
  isAFolder,
  isAForm,
  isAnImage,
  isAPDF,
  isAPowerpoint,
  isASlide,
  isAVideo,
  isEmpty,
  isExportable,
  macros,
} from '~/utils/utils';

const Document = ({
  content,
  file,
  indexPage,
}: {
  content: string;
  file: DriveFile | undefined;
  indexPage?: DriveFile | undefined;
}) => {
  const fileType = getMimeTypeNameFromFile(file);
  return (
    <div
      className={cx(pageStyles.page, fileType)}
      dangerouslySetInnerHTML={{
        __html: (indexPage ? content.replace(macros.replace.files, /*html*/ `<div data-react="Tree"></div>`) : content)
          .replace(
            macros.replace.checkedbox,
            /*html*/ `<span data-react="Checkbox" data-props='${JSON.stringify({ checked: true })}'></span>`
          )
          .replace(macros.replace.checkbox, /*html*/ `<span data-react="Checkbox"></span>`),
      }}
    />
  );
};

const PageContent = ({
  content,
  file,
  indexPage,
  url,
  isLoading,
  height,
}: {
  content?: string;
  file: DriveFile | undefined;
  indexPage?: DriveFile | undefined;
  url?: string | undefined;
  isLoading: boolean;
  height?: number | undefined;
}) => {
  const timerRef = useRef<Timer>(undefined);
  const [errorMessage, setError] = useState<string | undefined>();
  const [showSkeleton, setShowSkeleton] = useState(true);
  const hashLocation = useHashLocation()[0];
  useEffect(() => {
    if (hashLocation === '/') return;
    const id = hashLocation.replace(/^\//, '');
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
  }, [hashLocation]);

  useEffect(() => clearTimeout(timerRef.current), []);
  if (isLoading || (!file && !content)) {
    return (
      <div style={{ display: 'flex', gap: 8, alignItems: 'center', flexDirection: 'column', marginTop: '1.1rem' }}>
        <CircularProgress style={{ color: colors.spinner.default }} thickness={4} size={34} />
      </div>
    );
  }

  if (!file) {
    if (!content) return null;
    return <Document content={content} file={file} indexPage={indexPage} />;
  }

  if (isAPDF(file)) {
    return (
      <>
        <div className={cx(appStyles.relative, appStyles.tac)}>
          {showSkeleton && (
            <Skeleton
              className={appStyles.fadeIn}
              style={{ position: 'absolute', left: 0, right: 0, top: 0, margin: 'auto' }}
              variant="rectangular"
              width={805}
              height="100%"
            />
          )}
          <div
            style={{
              maxWidth: 805,
              position: 'absolute',
              margin: '0 auto',
              left: 0,
              right: 0,
              top: '-2rem',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <Tooltip title="Open in new tab">
              <a href={url} target="_blank" style={{ color: 'inherit', display: 'inline-flex' }}>
                <OpenInNew
                  style={{
                    cursor: 'pointer',
                    width: 24,
                    height: 24,
                    padding: 2.5,
                  }}
                />
              </a>
            </Tooltip>
          </div>

          {url ? (
            <iframe
              src={url + '#view=FitH'}
              title={file.name}
              width={805}
              height={height ?? '50vh'}
              style={{
                border: 'none',
                background: 'transparent',
              }}
              onLoad={(e) => {
                const { target } = e;
                if (!target || !(target instanceof HTMLIFrameElement)) return;
                target.style.opacity = '1';
                timerRef.current = setTimeout(() => setShowSkeleton(false));
              }}
            />
          ) : (
            <div style={{ height: height ?? '50vh' }} />
          )}
        </div>
      </>
    );
  }

  // Slides and Powerpoint
  if (isASlide(file) || isAPowerpoint(file)) {
    const { innerWidth } = window;
    const aspectRatio = innerWidth > 1728 ? '16 / 9.42' : '16 / 9.68';
    const timeout = 600;
    const delay = (timeout + 100) / 100;
    return (
      <>
        <div className={appStyles.relative}>
          {showSkeleton && (
            <Skeleton
              className={appStyles.fadeIn}
              style={{ position: 'absolute', left: 0, top: 0 }}
              variant="rectangular"
              width="100%"
              height="100%"
            />
          )}
          <iframe
            src={file.webViewLink.replace(/\/(edit|view)\?usp=drivesdk.*/, '/preview')}
            title={file.name}
            width="100%"
            height="100%"
            style={{
              border: 'none',
              aspectRatio,
              background: 'transparent',
              opacity: 0,
              transition: `opacity .3s .${delay}s`,
            }}
            onLoad={(e) => {
              const { target } = e;
              if (target instanceof HTMLElement) {
                target.style.opacity = '1';
              }
              timerRef.current = setTimeout(() => setShowSkeleton(false), timeout);
            }}
          />
        </div>
      </>
    );
  }

  if (isADrawing(file)) {
    return (
      <>
        {errorMessage && (
          <Paper
            elevation={0}
            className={cx([appStyles.flex, appStyles.alignCenter])}
            style={{
              marginTop: 14,
              marginBottom: 14,
              border: '1px solid rgb(187 204 255)',
              padding: '1em',
              background: '#e5eafd',
              color: '#22275a',
              lineHeight: 1,
            }}
          >
            <ErrorOutline style={{ marginRight: 12, color: '#5b76f5' }} />
            {errorMessage}
          </Paper>
        )}
        {showSkeleton && (
          <Skeleton
            style={{
              display: 'block',
              height: '50vh',
              transform: 'none',
            }}
          />
        )}
        <img
          src={`https://docs.google.com/drawings/d/${file.id}/export?format=svg`}
          alt={file.name}
          style={{ visibility: isBun ? undefined : 'hidden' }}
          onError={(e) => {
            const { target } = e;
            if (target instanceof HTMLImageElement && file.thumbnailLink) {
              target.src = file.thumbnailLink?.replace(/=s\d+$/, '');
            }
            setError('Enable third-party cookies to view in high resolution.');
          }}
          onLoad={(e) => {
            setShowSkeleton(false);
            const { target } = e;
            if (target instanceof HTMLElement) {
              target.style.visibility = 'visible';
            }
          }}
        />
      </>
    );
  }

  if (isAForm(file)) {
    return (
      <>
        {showSkeleton && (
          <Skeleton
            style={{
              display: 'block',
              height: '50vh',
              transform: 'none',
              borderRadius: 10,
              maxWidth: 640,
              margin: '0 auto',
              position: 'relative',
              top: 12,
            }}
          />
        )}
        <iframe
          src={file.webViewLink.replace(/\/(edit|view)\?usp=drivesdk.*/, '/viewform?embedded=true')}
          title={file.name}
          width="100%"
          height="100%"
          style={{
            border: 'none',
            visibility: 'hidden',
            background: 'transparent',
            colorScheme: 'none',
            height: '50vh',
          }}
          onLoad={(e) => {
            const { target } = e;
            if (target instanceof HTMLElement) {
              setShowSkeleton(false);
              target.style.visibility = 'visible';
            }
          }}
        />
      </>
    );
  }

  if (isAnImage(file)) {
    const { width, height } = file.imageMediaMetadata || {};
    const isLarge = width && width > 2800;
    const size = isLarge ? 2800 : Math.max(width ?? 0, height ?? 0);
    const url = file.thumbnailLink?.replace(/=s\d+$/, `=s${size}`);

    return (
      <picture>
        <source srcSet={url} />
        <img
          src={file.webContentLink.replace('=download', '=preview')}
          className={appStyles.wh100}
          {...(DEV && {
            referrerPolicy: 'no-referrer',
          })}
          alt={file.name}
        />
      </picture>
    );
  }

  if (isAVideo(file)) {
    const timeout = 300;
    const delay = (timeout - 200) / 100;
    return (
      <div className={appStyles.relative}>
        {showSkeleton && (
          <div className={appStyles.fadeIn}>
            <Skeleton
              style={{ position: 'absolute', left: 0, top: 0 }}
              variant="rectangular"
              width="100%"
              height="100%"
            />
          </div>
        )}
        <iframe
          allowFullScreen
          src={file.webViewLink.replace(/\/(edit|view)\?usp=drivesdk.*/, '/preview')}
          title={file.name}
          style={{
            width: '100%',
            border: 'none',
            aspectRatio: '16 / 9',
            opacity: showSkeleton ? 0 : 1,
            transition: `opacity .3s .${delay}s`,
          }}
          onLoad={(e) => {
            const { target } = e;
            if (target instanceof HTMLElement) {
              target.style.opacity = '1';
            }
            timerRef.current = setTimeout(() => setShowSkeleton(false), timeout);
          }}
        />
      </div>
    );
  }

  if (isAFolder(file) && !content) return;
  if (!content && file.mimeType === 'application/octet-stream') {
    return (
      <Paper
        elevation={0}
        className={cx([appStyles.flex, appStyles.alignCenter])}
        style={{
          marginTop: 14,
          border: '1px solid rgb(187 204 255)',
          padding: '1em',
          background: '#e5eafd',
          color: '#22275a',
          lineHeight: 1,
        }}
      >
        <ErrorOutline style={{ marginRight: 12, color: '#5b76f5' }} />
        File format not supported
      </Paper>
    );
  }

  if (!content) {
    if (isExportable(file)) return;
    return (
      <Paper
        elevation={0}
        className={cx([appStyles.flex, appStyles.alignCenter])}
        style={{
          marginTop: 14,
          border: '1px solid #ffbfbb',
          padding: '1em',
          background: '#FDECEA',
          color: '#611a15',
          lineHeight: 1,
        }}
      >
        <ErrorOutline style={{ marginRight: 12, color: '#f44336' }} />
        File format not yet supported, please request via:&nbsp;
        <a
          target="_blank"
          href={`mailto:grant@youneedawiki.com?subject=File%20format%20request%3A%20${file.mimeType}&body=Please%20add%20support%20for%20this%20format%3A%20${file.mimeType}`}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          hello@youneedawiki.com
          <OpenInNew fontSize="inherit" style={{ marginTop: 3 }} />
        </a>
      </Paper>
    );
  }

  if (isEmpty(content)) {
    return <Edit href={file.webViewLink.replace(/\?usp=drivesdk$/, '')} />;
  }
  return <Document content={content} file={file} indexPage={indexPage} />;
};

export const Page = (props: PropsOf<typeof PageContent>) => {
  const navigate = useLocation()[1];
  const pageRef = useRef<HTMLDivElement>(null);
  const id = props.file?.id;
  const url = useLast(props.url, [id]) ?? props.url;
  const fileType = getMimeTypeNameFromFile(props.file);

  // Handle any link clicks and useLocation
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const { target } = e;
      if (!target || !(target instanceof HTMLAnchorElement) || !pageRef?.current?.contains(target)) return;
      const { href } = target;
      if (!href) return;
      const url = new URL(href);
      if (!url || !url.hostname) return;

      // Skip if it's the current page
      const { pathname } = window.location;
      if (pathname === url.pathname) return;

      const isInternal =
        url.hostname === window.location.hostname ||
        url.hostname === 'localhost' ||
        url.hostname === 'youneedawiki.com';
      if (!isInternal) return;
      if (!url.pathname.startsWith('/app')) return;
      e.preventDefault();
      const path = href.replace(url.origin, '');
      navigate(path);
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [navigate]);

  return (
    <div ref={pageRef} className={cx(pageStyles.page, fileType)}>
      <PageContent {...props} url={url} key={id} />
    </div>
  );
};

/**
 * Used for testing with playwright
 */
export const PageSpec = ({ html, file }: { file?: DriveFile; html: string }) => {
  const { content } = parseExport({ html });
  return <PageContent isLoading={false} content={content} file={file} />;
};
