import { useRef } from 'react';
import { type Middleware, type SWRHook, useSWRConfig } from 'swr/_internal';

/**
 * Throttles cached requests to avoid unnecessary fetches
 */
export const throttle: Middleware = (useSWRNext: SWRHook) => (key, fetcher, config) => {
  const pendingRef = useRef<ReturnType<typeof Promise.withResolvers>>(undefined);
  const cache = useSWRConfig().cache;

  const extendedFetcher = async (...args: string[]) => {
    const id = args[0];
    if (!id) throw new Error('No id provided');
    if (!fetcher) return undefined as typeof result;

    if (pendingRef.current && cache.get(id)?.data !== undefined) {
      pendingRef.current.resolve(false);
      pendingRef.current = Promise.withResolvers();
      const proceed = await pendingRef.current.promise;
      if (!proceed) return Promise.reject(new Error('Throttled'));
    }

    pendingRef.current = Promise.withResolvers();
    const result = await fetcher(...args);

    if (pendingRef.current) {
      pendingRef.current.resolve(true);
      pendingRef.current = undefined;
    }

    return result;
  };

  return useSWRNext(key, extendedFetcher, config);
};
