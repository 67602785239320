import './polyfills.ts';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { SWRConfig, type Cache, type SWRConfiguration } from 'swr';

import App from './App.tsx';
import Login from './Login.tsx';
import './index.css';

// Legacy
import r2wc from '@r2wc/react-to-web-component';
import { dequal } from 'dequal/lite';
// import { cache } from 'swr/_internal';
import { Route, Switch } from 'wouter';
import LastModified from './components/LastModified/LastModified.tsx';
import * as pageStyles from './components/Page/Page.css';
import { Page } from './components/Page/Page.tsx';
import PrevNext from './components/PrevNext/PrevNext.tsx';
import Search from './components/Search/Search.tsx';
import { StaticTree } from './components/Tree/Tree.tsx';
import Button from './components/WikiSelector/WikiSelector.tsx';
import Breadcrumbs from './elements/Breadcrumbs/Breadcrumbs.tsx';
import Icon from './elements/Icon/Icon.tsx';
import PoweredBy from './elements/PoweredBy/PoweredBy.tsx';
import Title from './elements/Title/Title.tsx';
import { lightTheme } from './theme.css.ts';
import { type DriveFile, type ExportResult } from './utils/DriveAPI.ts';
import { debug, DEV, expiredToken, host, isIOS, protocol } from './utils/constants.ts';
import { log } from './utils/logger.ts';
import type { Profile, PropsOf } from './utils/types.ts';
import { get } from './utils/utils.ts';

const root = document.getElementById('root');
const cacheName = 'app-cache';
const cacheKey = 'wiki-cache';

if (debug) {
  const cache = await caches.open(cacheName);
  await cache.delete(cacheKey);
  localStorage.clear();
}

// Testing
// if (DEV) {
//   const cache = await caches.open(cacheName);
//   await cache.delete(cacheKey);
// }

if (DEV) {
  await import('./utils/runtime-error-overlay.ts');
}

// Clear console on hot reload
if (import.meta.hot) {
  import.meta.hot?.on('vite:beforeUpdate', () => {
    console.clear();
  });
}

async function createCacheProvider() {
  // When testing locally using http for non-localhost URL's, the cache is disabled in the browser.
  const isHttpDev = DEV && protocol === 'http:' && host !== 'localhost';
  const cache = isHttpDev
    ? { match: () => ({ json: () => {} }), put: () => {}, delete: () => {} }
    : await caches.open(cacheName);

  // When initializing, we restore the data from the Cache Storage into a map.
  const json = ((await (await cache.match(cacheKey))?.json()) ?? []) as [string, string][];
  const map = new Map<string, unknown>(json);

  if (DEV) {
    // @ts-expect-error - enabled for debugging
    window.cacheMap = map;
  }

  let unloaded = false;
  const saveCache = () => {
    if (unloaded) return;
    unloaded = true;

    // Remove blob urls from cache
    for (const [key, value] of map.entries()) {
      const val = get<{ data: ExportResult }>(value);
      const data = val?.data;
      const url = data?.url;
      if (val && url?.startsWith('blob:')) {
        map.set(key, { ...val, data: { ...data, url: undefined } });
      }
    }

    if (DEV && expiredToken) {
      const user = map.get('/api/user/me');
      const val = get<{ data: Profile }>(user);
      if (val) {
        map.set('/api/user/me', {
          ...val,
          data: {
            ...val.data,
            tokens: {
              accessToken: 'expired',
              expires: 0,
              expiresAt: Date.now(),
            },
          },
        });
      }
    }

    const appCache = JSON.stringify(Array.from(map.entries()));
    void cache.put(cacheKey, new Response(appCache));
  };

  // https://github.com/segmentio/analytics-next/blob/master/packages/browser/src/lib/on-page-change.ts
  if (isIOS) {
    window.addEventListener('pagehide', saveCache);
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') saveCache();
      else unloaded = false;
    });
  }

  window.addEventListener('beforeunload', saveCache);

  // We still use the map for write & read for performance.
  return () => map as Cache;
}

export const cacheStorageProvider = typeof window.caches === 'undefined' ? undefined : await createCacheProvider();

// Legacy web components
if (import.meta.env.VITE_LEGACY) {
  type Props = Parameters<typeof Search>[0];

  const SearchWC = (props: Props) => (
    <SWRConfig value={cacheStorageProvider ? { provider: cacheStorageProvider } : undefined}>
      <div className={lightTheme} data-theme="light">
        <Search {...props} />
      </div>
    </SWRConfig>
  );
  const searchComponentName = 'wc-search';
  if (!customElements.get(searchComponentName)) {
    customElements.define(
      searchComponentName,
      r2wc(SearchWC, {
        props: {
          files: 'json',
          wiki: 'json',
        },
      })
    );
  }

  const titleComponentName = 'wc-title';
  const TitleWC = (props: { title: string }) => (
    <div className={lightTheme} data-theme="light">
      <div className={pageStyles.titleContainer}>
        <Title className={pageStyles.title}>{props.title}</Title>
      </div>
    </div>
  );
  if (!customElements.get(titleComponentName)) {
    customElements.define(titleComponentName, r2wc(TitleWC, { props: { title: 'string' } }));
  }

  const lastModifiedName = 'wc-last-modified';
  const LastModifiedWC = ({ content, file }: { content?: string; file: DriveFile }) => (
    <div className={lightTheme} data-theme="light">
      <LastModified file={file} content={content} />
    </div>
  );
  if (!customElements.get(lastModifiedName)) {
    customElements.define(lastModifiedName, r2wc(LastModifiedWC, { props: { content: 'string', file: 'json' } }));
  }

  const breadcrumbsName = 'wc-breadcrumbs';
  const BreadcrumbsWC = ({ file, files, wiki }: { file: DriveFile; files: DriveFile[]; wiki: DriveFile }) => (
    <div className={lightTheme} data-theme="light">
      <Breadcrumbs files={files} file={file} wiki={wiki} />
    </div>
  );
  if (!customElements.get(breadcrumbsName)) {
    customElements.define(
      breadcrumbsName,
      r2wc(BreadcrumbsWC, { props: { file: 'json', files: 'json', wiki: 'json' } })
    );
  }

  const prevNextName = 'wc-prev-next';
  const PrevNextWC = ({ file, files, wiki }: { file: DriveFile; files: DriveFile[]; wiki: DriveFile }) => (
    <SWRConfig value={cacheStorageProvider ? { provider: cacheStorageProvider } : undefined}>
      <div className={lightTheme} data-theme="light">
        <PrevNext file={file} files={files} wiki={wiki} />
      </div>
    </SWRConfig>
  );
  if (!customElements.get(prevNextName)) {
    customElements.define(prevNextName, r2wc(PrevNextWC, { props: { file: 'json', files: 'json', wiki: 'json' } }));
  }

  const wikiSelectorName = 'wc-wiki-selector';
  const WikiSelectorWC = ({ wiki, wikis, user }: { wiki: DriveFile; wikis: DriveFile[]; user: Profile }) => (
    <div className={lightTheme} data-theme="light">
      <Button wiki={wiki} wikis={wikis} user={user} />
    </div>
  );
  if (!customElements.get(wikiSelectorName)) {
    customElements.define(
      wikiSelectorName,
      r2wc(WikiSelectorWC, { props: { wiki: 'json', wikis: 'json', user: 'json' } })
    );
  }

  const poweredByName = 'wc-powered-by';
  const PoweredByWC = () => (
    <div className={lightTheme} data-theme="light">
      <PoweredBy />
    </div>
  );

  if (!customElements.get(poweredByName)) {
    customElements.define(poweredByName, r2wc(PoweredByWC, {}));
  }

  const iconName = 'wc-icon';
  const IconWC = (props: {
    file: DriveFile;
    color: string;
    hasChildren: boolean;
    sidebar: boolean;
    bgColor?: string;
  }) => {
    return (
      <div className={lightTheme} data-theme="light">
        <Icon {...props} />
      </div>
    );
  };
  if (!customElements.get(iconName)) {
    customElements.define(
      iconName,
      r2wc(IconWC, {
        props: { file: 'json', color: 'string', hasChildren: 'boolean', sidebar: 'boolean', bgColor: 'string' },
      })
    );
  }

  const staticTreeName = 'wc-static-tree';
  const staticTreeWC = (props: { wiki: DriveFile; files: DriveFile[]; currentParentId: string }) => (
    <div className={lightTheme} data-theme="light">
      <StaticTree {...props} depth={1} />
    </div>
  );

  if (!customElements.get(staticTreeName)) {
    customElements.define(
      staticTreeName,
      r2wc(staticTreeWC, {
        props: { wiki: 'json', files: 'json', currentParentId: 'string' },
      })
    );
  }

  const pageName = 'wc-page';
  const PageWC = (props: PropsOf<typeof Page>) => (
    <div className={lightTheme} data-theme="light">
      <Page {...props} />
    </div>
  );
  if (customElements.get(pageName)) {
    customElements.define(
      pageName,
      r2wc(PageWC, {
        props: {
          content: 'string',
          height: 'string',
          file: 'json',
          indexPage: 'json',
          isLoading: 'boolean',
          url: 'string',
        },
      })
    );
  }
  // End of legacy app
} else if (root) {
  const config = {
    revalidateOnReconnect: false,
    compare: dequal, // https://github.com/vercel/swr/issues/1719
    onError: (err, key: string) => {
      log.error('SWR error for path:', key);
      if (window.gapi) {
        log.info('token:', gapi.client.getToken());
      }
      if (err && 'result' in err && err.result.error) {
        log.info(err.result.error);
        return;
      }
      log.error(err);
    },
    // onErrorRetry: async (err, key, _config, _revalidate, { retryCount }) => {
    //   const { code, status } = err.result.error;
    //   if (requestInProgress) return;

    //   // Retry on 401
    //   if (code === 401 && status === 'UNAUTHENTICATED' && key.startsWith('/drive') && retryCount < 2) {
    //     log.info('fetching user (UNAUTHENTICATED)');
    //     requestInProgress = true;
    //     const response = await fetch('/api/user/me');
    //     const user = (await response.json()) as ServerProfile | undefined;
    //     requestInProgress = false;
    //     log.info('setting /api/user/me:', user);
    //     if (!user) return;

    //     const data = {
    //       ...user,
    //       tokens: {
    //         ...user.tokens,
    //         expiresAt: Date.now() + user.tokens.expires,
    //       },
    //     };
    //     cache.set('/api/user/me', { data });
    //     gapi.client.setToken({
    //       access_token: user.tokens.accessToken,
    //       expires_in: user.tokens.expires,
    //       scope: user.tokens.scope,
    //     });
    //   }
    // },
  } satisfies SWRConfiguration<
    unknown,
    {
      body: string;
      headers: Headers;
      result: {
        error: {
          code: number;
          errors: unknown[];
          message: string;
          status: string;
        };
      };
      status: number;
      statusText: string | null;
    }
  >;
  // Modern app
  createRoot(root).render(
    <StrictMode>
      <SWRConfig value={cacheStorageProvider ? { ...config, provider: cacheStorageProvider } : config}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/auth" component={Login} />
          <Route path="*" component={App} />
        </Switch>
      </SWRConfig>
    </StrictMode>
  );
}
